@import "../vars";

.Brand{
  display: flex;
  flex-direction: column;
  appearance: none;
  padding: 0;
  cursor: pointer;

  background: #fff;
  color: #fff;
  text-decoration: none;
  align-items: stretch;
  border: none;
  border-radius: 4px;
  overflow: hidden;

  transition: all .3s ease-in-out;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);

  &:hover{
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  }

  .Brand-image{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    height: calc(150px);
    @media screen and (max-width: $bp-phone) {
      height: 80px;
    }
    picture, img{
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .Brand-text{
    background: $red;
    text-align: center;
    h3{
      margin: 16px 0;
      @include fp(font-size, 12, 16, 320, $max-width-pure);
      @media screen and (max-width: $bp-phone) {
        margin: 8px 0;
      }
    }
  }

  .loading-indicator {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      border: 4px solid $red;
      opacity: 1;
      border-radius: 50%;
      animation: loading-indicator 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
  @keyframes loading-indicator {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

}
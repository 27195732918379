@import "../_vars.scss";

.Marken{
  margin: 0 0 128px;
  overflow-y: visible;
  overflow-x: hidden;
  max-width: 100vw;
  position: relative;

  @media screen and (max-width: $bp-phone) {
    margin-bottom: 32px;
  }

  .Marken-Content{
    @media screen and (max-width: $max-width) {
      padding: 16px;
      //padding-top: 32px;
    }
  }

  .Marken-decoration{
    width: 100%;
    position: relative;
    z-index: 10;
    margin-bottom: -150px;
  }
  .Marken-group{
    width: 100%;
    max-width: $max-width;
    padding: 40px 0;
    margin: 0 auto;

    position: relative;
    z-index: 15;



    @media screen and (min-width: $bp-phone) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    @media screen and (max-width: $bp-phone) {
      display: flex;
      //grid-template-columns: repeat(3, 1fr);
      //grid-template-rows: repeat(4, 1fr);
      //padding: 16px;
      transform: none;
      flex-wrap: wrap;
      .Brand{
        flex: 1 0;
        min-width: calc(50% - 16px);
      }
    }

    grid-gap: 20px;
    .Brand{
      flex: 1 0;
    }
  }
  .Marken-details{
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    justify-content: center;
    @media screen and (max-width: $bp-phone) {
      padding: 0 16px;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;
      .col-button{
        order: -1;
      }
    }
  }

  .col-button{
    justify-content: center;
    align-items: flex-end;
    text-align: center;
  }
  .button{
    display: inline-flex;
    flex-grow: 0;
    background: $lime;
    color: $red;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 500;
    &:hover{
      background: $lime-accent-shade;
    }
  }
}

.Brands-Modal{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000, .8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px;
  animation: fadeIn .5s;
  @media screen and (max-width: $bp-phone) {
    padding: 32px 16px;
  }
  .Brands-Modal-Close{
    position: absolute;
    right: 32px;
    top: 32px + 16px;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 99999;
    @media screen and (max-width: $bp-phone) {
      right: 16px;
    }
  }
  .Brands-Modal-Content{
    padding: 32px;
    background: #fff;
    border-radius: 8px;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    animation: moveIn .5s;
    @media screen and (max-width: $bp-phone) {
      padding: 24px 0;
    }
    h1{
      margin: 0;
    }
    .MultiMap{
      max-width: 100%;
      @media screen and (max-width: $bp-phone) {
        .MultiMapContent {
          height: 70vh;
        }
      }
    }
  }
  .Brands-Modal-Closer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@keyframes fadeIn {
  0% { opacity: 0;}
  100% { opacity: 1;}
}

@keyframes moveIn {
  0% { transform: translateY(100%); }
  100% { transform: translateY(0%); }
}
@import "../vars";

.AnimatedKringelParent {
  position: relative;
}

.AnimatedKringelContainer {
  .gewinnspiel & {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
  }
}

.AnimatedKringel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 0;
  display: flex;
  overflow-x: hidden;

  h1,
  h2 {
    margin: 0 auto;
    position: absolute;
    color: transparent;
    display: none;
  }

  svg {
    width: 160vw;
    margin-left: -30vw;
    object-fit: cover;
    //aspect-ratio: 2.81;
    position: absolute;
    //display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //z-index: 0;
    //overflow-x: hidden;
    //overflow-y: hidden;
    max-height: 100%;
    path {
      stroke: none;
    }
    textPath {
      //transition: all .02s ease;
    }
    tspan {
      &.outline {
        .arbeitgeber & {
          stroke: $blue;
        }
      }
      &.filled {
        .arbeitgeber & {
          fill: $blue;
        }
      }
    }
  }
}

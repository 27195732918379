@import "fonts/DINPro.css";
@import "fonts/Radial.css";
@import "_vars.scss";

@import "slick-carousel/slick/slick.css";
@import "./helpers/slick-theme";

html, body{
  margin: 0;
  padding: 0;
  background: #fff;
  @media screen and (max-width: $bp-phone) {
    width: 100vw;
    overflow-x: hidden;
  }
}

body {
  margin: 0;
  font-family: $font-din;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  box-sizing: border-box;
  *{
    box-sizing: inherit;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

em{
  font-style: normal;
}


/**************************/
/* CSS Animations Classes */
/**************************/

/*
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  //transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  //transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 1000ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  //transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 1000ms ease;
}


h1, h2{
  @media screen and (max-width: $bp-phone) {
    word-break: break-all;
    // allow words to break with hyphens on phones
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    -webkit-hyphenate-limit-before: 3; /* For Safari */
    -webkit-hyphenate-limit-after: 4; /* For Safari */
    -ms-hyphenate-limit-chars: 10 3 4;
    hyphenate-limit-chars: 10 3 4;
  }
}

h1{
  text-transform: uppercase;
  font-family: $font-radial;
  font-weight: 700;

  @include fp(font-size, 32, 64, 320, $max-width-pure);
  @media screen and (max-width: $bp-phone) {
    font-size: 48px;
  }
}

h2{
  font-family: $font-radial;
  font-weight: 700;
  text-transform: uppercase;
  @include fp(font-size, 32, 48, 320, $max-width-pure);
}

h3{
  font-size: 24px;
  font-family: $font-radial;
  font-weight: 700;
  @media screen and (max-width: $bp-phone) {
    font-size: 18px;
  }
}

h4{
  font-size: 24px;
  font-family: $font-din;
  font-weight: 500;
  @media screen and (max-width: $bp-phone) {
    font-size: 18px;
  }
}

.noBreak{
  word-break: normal;
}

.text-center{
  width: 100%;
  display: inline-block;
  text-align: center;
}

.Rechtliches{
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  padding: 16px;
  font-size: 20px;
  p{
    word-wrap: break-word;
  }
}

.mobile-only{
  @media screen and (min-width: $bp-phone) {
    display: none !important;
  }
}

.desktop-only{
  @media screen and (max-width: $bp-phone) {
    display: none !important;
  }
}

.Kringel{
  width: 100vw;
  height: 600px;
  margin-bottom: -300px;
  position: relative;
  z-index: 1;
  pointer-events: none;
  &.mb200{
    margin-bottom: -200px;
  }
  @media screen and (max-width: $bp-phone) {
    height: 200px;
    margin-bottom: -100px;
    &.mb200{
      margin-bottom: -50px;
    }
    &.mh100{
      height: 130px;
    }
  }
  .Kringel-blue{
    display: none;
    .arbeitgeber & {
      display: flex;
    }
  }
  .Kringel-red{
    display: flex;
    .arbeitgeber & {
      display: none;
    }
  }
  .Kringel-Image{
    width: 100%;
    height: 100%;
    img{
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


@import "../vars";

.Tooltip {
  position: relative;
  cursor: default;

  &:hover {
    .Tooltip-Content {
      transition: opacity 200ms ease-in-out;
      opacity: 1;
      visibility: visible;
      display: flex;
      padding: 8px 16px;
      border-radius: 4px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, calc(100% + 10px));
      background: rgba($red-shade-darker, 0.9);
      backdrop-filter: blur(2px);
      border: 1px solid rgba($red, 0.3);
      font-size: 14px;
      min-width: 256px;
      color: #fff;
      z-index: 1000;
      &:before {
        background-color: $red-shade-darker;
        content: " ";
        visibility: visible;
        opacity: 1;
        position: absolute;
        width: 15px;
        height: 15px;
        z-index: 999;
        top: 0;
        left: 50%;
        transition: opacity 200ms ease-in-out;
        transform: translate(-50%, calc(0% - 5px)) rotate(45deg);
      }
    }
  }

  .Tooltip-Content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    white-space: nowrap;
    z-index: 1000;

    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: 100%;
      left: 0 !important;
      bottom: calc(100% + 8px) !important;
      right: 0 !important;
      transform: none !important;
      text-wrap: normal;
      white-space: break-spaces;
      display: block !important;
      height: auto !important;
      padding: 8px !important;
      min-width: 128px !important;
      position: absolute !important;
      font-size: 12px !important;
      pointer-events: none;
      &:before {
        top: 98% !important;
      }
    }
  }
}

@import "../../../vars";

.MapPopup{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000, .8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px;
  animation: fadeIn .5s;
  @media screen and (max-width: $bp-phone) {
    padding: 32px 16px;
  }
  .MapPopup-Close{
    position: absolute;
    right: 32px;
    top: 32px + 16px;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 99999;
    @media screen and (max-width: $bp-phone) {
      right: 16px;
    }
  }
  .MapPopup-Content{
    padding: 32px;
    background: #fff;
    border-radius: 8px;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    animation: moveIn .5s;
    @media screen and (max-width: $bp-phone) {
      padding: 24px 0;
    }
    h1{
      margin: 0;
    }
    .MultiMap{
      max-width: 100%;
      @media screen and (max-width: $bp-phone) {
        .MultiMapContent {
          height: 70vh;
        }
      }
    }
  }
  .MapPopup-Closer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
@import "../vars";

.Advantages{
  width: 100%;
  margin-top: 100px;
  .Advantages-Image{
    width: 100%;
    height: 400px;
    margin-bottom: -100px;
    position: relative;
    z-index: 1;
    img{
      display: flex;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .Advantages-List{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    @media screen and (max-width: $bp-phone) {
      gap: 32px;
      padding: 16px;
    }
  }
  .Advantages-Banner{
    display: flex;
    flex-direction: column;
    //background: $red;
    color: #fff;
    text-align: center;
    align-items: center;
    padding: 32px;
    @media screen and (max-width: $bp-phone) {
      padding: 0px;
    }
    p{
      max-width: calc(#{$max-width} / 3);
      font-weight: 500;
    }
    .button{
      padding: 16px 32px;
      background: $lime;
      color: $red;
      text-decoration: none;
      border-radius: 8px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 32px;
      @include fp(font-size, 14, 32, 320, $max-width-pure);
      @media screen and (max-width: $bp-phone) {
        width: 100%;
      }
      .arbeitgeber &{
        background: $blue-accent;
        color: $blue;
      }
      .haendler &{
        background: $red;
        color: $lime;
      }
      &:hover{
        background: $lime-accent-shade;
        .arbeitgeber &{
          background: $blue-shade-darker;
          color: $blue-accent;
        }
        .haendler &{
          background: $red-shade;
        }
      }
    }
  }
}


@import "../vars";

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 16px 16px;
  position: sticky;
  top: 0;
  left: 0;
  height: $header-height;
  right: 0;
  z-index: 9999;
  background: $red;
  .arbeitgeber & {
    background: $blue;
  }
  .haendler & {
    background: $lime;
  }
  .index_php & {
    display: none !important;
  }

  //&:after {
  //  display: block;
  //  background: $lime;
  //  content: "Wegen technischer Probleme ist Firmenradl derzeit leider nur eingeschränkt verfügbar. Wir arbeiten mit Hochdruck an einer Lösung und entschuldigen uns für entstandene Unannehmlichkeiten!";
  //  position: absolute;
  //  top: 100%;
  //  left: 0;
  //  right: 0;
  //  text-align: center;
  //  padding: 16px;
  //}

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

  @media screen and (max-width: $bp-phone) {
    height: auto;
    position: fixed;
    top: 0;
  }

  &.hide {
    @media screen and (min-width: $bp-phone) {
      top: -100px;
      .logo {
        height: 30px;
      }
    }

    //.bm-burger-bars {
    //  background: $red;
    //  .arbeitgeber &{
    //    background: $blue;
    //  }
    //  .haendler &{
    //    background: $lime;
    //  }
    //}
  }

  .logo {
    width: auto;
    height: 60px;
    display: inline-block;
    transition: all 0.3s ease;
    img {
      width: auto;
      height: 100%;
      &.red {
        display: none;
        .haendler & {
          display: block;
        }
      }
      &.white {
        display: block;
        .haendler & {
          display: none;
        }
      }
    }
    @media screen and (max-width: $bp-phone) {
      display: flex;
      height: 32px;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 0;
    //max-width: $max-width;
    @media screen and (min-width: $bp-phone) {
      max-width: calc(100vw - 200px);
    }
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 16px;
    @media screen and (max-width: $bp-phone) {
      display: none;
    }
    li {
      list-style: none;
    }
  }
  nav {
    a {
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      transition: all 1s ease;
      .haendler & {
        color: #000;
      }
      &.active {
        //text-decoration: underline;
        color: $lime;
        .arbeitgeber & {
          color: $blue-accent;
        }
        .haendler & {
          color: $red;
        }
      }
      &.button {
        display: flex;
        padding: 8px 16px;
        background: #fff;
        color: $red-shade;
        border-radius: 8px;
        .arbeitgeber & {
          color: $blue;
        }
        .haendler & {
          background: #000;
          color: $lime;
        }
      }
    }
    &.desktop {
      @media screen and (max-width: $bp-phone) {
        display: none !important;
      }
    }
    &.mobile {
      @media screen and (min-width: $bp-phone) {
        display: none !important;
      }
      display: flex;
      width: 32px;
      height: 32px;
      text-align: center;
      a {
        margin-bottom: 16px;
      }
    }

    /* Position and sizing of burger button */
    .bm-burger-button {
      position: absolute;
      right: 16px;
      top: 24px;
      width: 24px;
      height: 20px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background-color: #fff;
      transition: all 0.3s ease;
      .haendler & {
        //background: #000;
      }
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: $lime;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 48px;
      width: 48px;
      //transform: scale(2);
      top: 32px !important;
      right: 32px !important;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: #fff;
      .haendler & {
        background: #000;
      }
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      top: 0;
    }

    /* General sidebar styles */
    .bm-menu {
      background: $red;
      padding: 2.5em 1.5em 0;
      font-size: 32px;
      .arbeitgeber & {
        background: $blue;
      }
      .haendler & {
        background: $lime;
      }
      a {
        font-size: 32px;
      }
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      padding: 0.8em;
    }

    /* Individual item */
    .bm-item {
      display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

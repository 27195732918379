@import "../../../vars";

$imageWidth: 64px;
$gap: 16px;

.MapMarkerPopup {
  display: flex;
  flex-direction: column;
  font-family: $font-din;
  position: relative;
  max-width: 340px;
  min-width: 200px;
  gap: 40px;
  .group {
    display: flex;
    flex-direction: column;
    .partner,
    .firm {
      .header {
        display: flex;
        flex-direction: row;
        gap: 16px;
        padding: 16px 0;
        .text {
          flex: 1;
          .address {
            text-transform: capitalize;
          }
        }
        .image {
          width: $imageWidth;
          min-width: $imageWidth;
        }
      }
      .footer {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: 20px;
    color: $red;
    margin: 0;
    &.blue {
      color: $blue;
    }
  }
  img {
    margin: 8px 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    object-fit: contain;
  }
  ul {
    margin: 0;
    padding-left: 2em;
    li {
      margin: 4px 0;
    }
  }
  .socials {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    a {
      display: inline-flex;
      height: 24px;
      margin: 0;
      align-items: center;
      img {
        margin: 0;
        display: inline-flex;
      }
      &.website {
        background: $red;
        color: #fff;
        border-radius: 4px;
        padding-left: 8px;
        padding-right: 8px;
        text-decoration: none;
      }
    }
  }
  .type {
    text-align: center;
    margin-top: 8px;
    color: $red;
  }
  .brands {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    gap: 8px;
    max-width: 320px;
    margin-top: 8px;
    li {
      display: inline-flex;
      padding: 4px 12px;
      //color: #fff;
      background: #f8f8f8;
      height: 24px;
      border-radius: 12px;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }
}

@import "../vars";

.Gewinnspiel {
  width: 100%;
  max-width: 800px;
  margin: 40px auto;
  padding: 0 16px;
  h1 {
    color: $red;
    margin-top: 3em;
    font-family: $font-radial;
    @include fp(font-size, 24, 48, 320, 800);
  }
  h2 {
    color: $red;
    margin-top: 3em;
    font-family: $font-radial;
    @include fp(font-size, 20, 40, 320, 800);
  }
  button {
    display: inline-flex;
    flex-grow: 0;
    background: $red;
    color: $lime;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    cursor: pointer;
    &:hover {
      background: $red-shade-darker;
    }
  }
  ul {
    list-style: none;
    li {
      margin-bottom: 1em;
      em {
        font-weight: 700;
      }
      &::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $red; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
}

@import "../vars";

.Hero {
  display: flex;
  width: 100%;
  height: calc(100vh - #{$header-height});
  position: relative;
  align-items: center;
  justify-content: flex-end;
  background: $blue;
  overflow-x: hidden;
  overflow-y: visible;
  @media screen and (max-width: $bp-phone) {
    flex-direction: column;
  }
  .haendler & {
    background: $lime;
  }
  .gewinnspiel & {
    background: $red;
  }
  .Hero-Image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    picture,
    img {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  .Hero-Text {
    position: relative;
    z-index: 20;
    width: 100%;
    max-width: 520px;
    @include fp(max-width, 320, 680, 320, 1920);
    padding: 16px;
    @media screen and (min-width: $bp-phone) {
      margin-right: 5vw;
      margin-bottom: 10vh;
    }
    @media screen and (max-width: $bp-phone) {
      background: rgba($blue-shade-darker, 0.5);
      backdrop-filter: blur(2px);
      .haendler & {
        background: rgba($lime, 0.8);
      }
      .gewinnspiel & {
        background: rgba($red, 0.8);
      }
    }
    h1 {
      color: $blue-accent;
      font-family: $font-radial;
      font-weight: 700;
      line-height: 120%;
      @include fp(font-size, 32, 64, 320, 1920);
      .haendler & {
        color: $red;
      }
      .gewinnspiel & {
        color: $lime;
      }
    }
    p {
      font-size: 24px;
      font-family: $font-radial;
      font-weight: 700;
      color: #fff;
      @include fp(font-size, 18, 36, 320, 1920);
      .haendler & {
        color: #000;
      }
    }
    .button {
      display: inline-flex;
      padding: 16px 32px;
      background: $blue-accent;
      color: $blue;
      font-family: $font_din;
      font-weight: 700;
      text-decoration: none;
      border-radius: 8px;
      text-transform: uppercase;
      @include fp(font-size, 14, 32, 320, $max-width-pure);
      @media screen and (max-width: $bp-phone) {
        width: 100%;
        text-align: center;
      }
      .haendler & {
        background: $red;
        color: $lime;
      }
      .gewinnspiel & {
        background: $lime;
        color: $red;
      }
    }
  }
}

@import "../vars";

.Advantage{
  display: grid;
  grid-gap: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  background: $red;

  .arbeitgeber &{
    background: $blue;
  }
  .haendler &{
    background: $lime;
  }

  @media screen and (min-width: $bp-phone) {
    min-height: 400px;
  }

  @media screen and (max-width: $bp-phone) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 0;
  }

  &:nth-child(2n){
    @media screen and (min-width: $bp-phone) {
      .Advantage-Text {
        order: 5;
      }
    }
  }

  .Advantage-Image{
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    @media screen and (max-width: $bp-phone) {
      height: 40vh;
      max-width: 100vw;
    }

    img, picture{
      position: absolute;
      display: flex;
      line-height: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100vw;
      object-position: 25% center;
    }
  }
  .Advantage-Text{
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: #fff;
    @media screen and (max-width: $bp-phone) {
      padding: 32px;
    }
    .haendler &{
      color:#000;
    }
    h2{
      text-transform: uppercase;
      color: $lime;
      margin: 0;
      word-break: break-word;

      .arbeitgeber &{
        color: $blue-accent;
      }
      .haendler &{
        color: $red;
      }
    }
    p{
      margin: 0;
      @include fp(font-size, 18, 24, 320, $max-width-pure);
      em{
        font-weight: 700;
      }
    }
    ul{
      font-size: 24px;
      font-weight: 700;
      font-family: $font-radial;
      margin-left: 0;
      padding: 16px;
      list-style: none;
      @include fp(font-size, 18, 24, 320, $max-width-pure);
      li{
        padding: 0;
        margin-bottom: 24px;

        &:first-letter{
          //color: $red;
        }
        &::before {
          content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: $lime; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-left: -1em; /* Also needed for space (tweak if needed) */

          .arbeitgeber &{
            background: $blue-accent;
          }
          .haendler &{
            background: $red;
          }
        }
      }
    }
    .content{
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
}


@import "../vars";

.kontakt-section {
  width: 100%;
  max-width: $max-width;
  margin: 40px auto;
  padding: 0 16px;

  h1 {
    color: $red;
    margin-top: 3em;
    margin-bottom: 1em;
    font-family: $font-radial;
    @include fp(font-size, 24, 64, 320, 800);
    text-align: center;
  }

  .kontakt-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    gap: 40px;
    justify-content: center;
    max-width: $max-width; // Adjust this value to control the maximum width of the grid
    margin: 0 auto; // This centers the grid
  }

  .kontakt-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    img {
      width: 220px;
      height: 280px;
      object-fit: cover;
      margin-right: 20px;
    }

    > div {
      flex: 1;
    }
  }

  h1 {
    color: $red;
    font-family: $font-radial;
    text-transform: none;
    @include fp(font-size, 20, 40, 320, 800);

    a {
      color: $red;
      display: inline; // Ensures the link stays inline
      text-decoration: none; // Optional: removes underline from links

      &:hover {
        text-decoration: underline; // Optional: adds underline on hover
      }
    }
  }

  h2 {
    color: $red;
    text-align: center;
    margin-top: 0;
    margin-bottom: 3rem;
    font-family: $font-radial;
    @include fp(font-size, 20, 40, 320, 800);
  }

  h3 {
    color: #000;
    font-size: 2rem;
    line-height: 100%;
    margin-top: 8px;
    font-family: $font-din;
  }

  p {
    margin: 4px 0;
    color: #000;
  }

  a {
    display: block;
    color: #000;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    margin: 4px 0;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 768px) {
  .kontakt-section {
    .kontakt-grid {
      grid-template-columns: 1fr;
      max-width: 450px; // Adjust for single column layout
    }
  }
}

@media (max-width: 600px) {
  .kontakt-section {
    .kontakt-card {
      flex-direction: column;
      align-items: center;
      text-align: center;

      img {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
}
@import "../vars";

.FAQ {
  width: 100%;
  margin: 0 auto 200px;

  @media screen and (max-width: $bp-phone) {
    margin-bottom: 32px;
  }

  .FAQ-Content {
    width: 100%;
    margin: 0 auto 32px;
    max-width: $max-width;
    padding: 0 16px;
    @media screen and (max-width: $bp-phone) {
      margin-bottom: 32px;
    }
  }

  .FAQ-ViewMore {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      padding: 8px 16px;
      background: $red;
      color: #fff;
      text-decoration: none;
      border-radius: 8px;
      text-transform: uppercase;
      font-weight: 500;
      border: none;
      appearance: none;
      cursor: pointer;
      .arbeitgeber & {
        background: $blue-accent;
        color: $blue;
      }
      .haendler & {
        background: $red;
      }
      &:hover {
        background: $red-shade-darker;
        .arbeitgeber & {
          background: $blue-shade-darker;
          color: $blue-accent;
        }
        .haendler & {
          background: $red-shade-darker;
        }
      }
    }
  }

  .Collapsible {
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    background: rgba(#fff, 0.9);

    position: relative;
    z-index: 10;
  }

  .Collapsible__trigger {
    font-size: 24px;
    font-weight: 500;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    @media screen and (max-width: $bp-phone) {
      font-size: 18px;
    }

    .Collapsible-Trigger {
      display: flex;
      flex: 1 0;
      .Collapsible-Trigger-Text {
        flex-grow: 1;
      }
    }

    img {
      transition: all 0.2s ease;
    }
    &.is-open {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .Collapsible__contentOuter {
    .Collapsible__contentInner {
      margin-top: 16px;
      width: 100%;
      //max-width: 800px;
      a {
        text-decoration: underline;
      }
    }
  }

  .searchInput {
    display: flex;
    width: calc(100vw - 32px);
    border: none;
    background: rgba(#fff, 0.3);
    border-bottom: 2px solid #ccc;
    margin: 16px auto;
    padding: 8px;
    max-width: $max-width;

    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    &:focus {
      outline: none;
      border-bottom-color: $red;
    }
    &::placeholder {
      opacity: 0.3;
    }
  }
}

.FAQ-decoration {
  width: 100%;
}

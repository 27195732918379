@import "../vars";

.admin {
  background: #f8f8f8;
}

.adminInterface {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  padding: 16px;
  margin: 0 auto;

  button {
    display: inline-flex;
    background: $is-blau;
    color: #fff;
    appearance: none;
    padding: 8px 16px;
    border-radius: 16px;
    border: none;
    cursor: pointer;
  }

  .buttonbar {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 8px;
    z-index: 9999;
    .row {
      max-width: 1280px;
    }
  }

  &.login {
    align-items: center;
    input,
    h1 {
      width: 320px;
    }
  }
  h1 {
    font-size: 20px;
  }

  .admin-faq {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 32px 0;
    border-bottom: 1px solid #666;
  }
  .faq-list {
    display: flex;
    flex-direction: column;
    //gap: 32px;
  }
  .group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    max-width: 960px;
    .group {
      flex-grow: 0;
      margin-top: 16px;
      flex-direction: row-reverse;
      gap: 8px;
    }
    input,
    textarea {
      width: 100%;
      &[type="checkbox"] {
        width: auto;
      }
    }
  }
  .rdw-editor-wrapper {
    border: 1px solid #000;
    background: #fff;
    display: flex;
    flex-direction: column;
    .public-DraftEditor-content {
      padding: 0px 8px;
      max-height: 70vh;
    }
  }

  .tabs {
    display: inline-flex;
    background: #eee;
    border-radius: 16px;
    .tab {
      padding: 8px 16px;
      cursor: pointer;
      &.active {
        color: $is-blau;
      }
    }
  }

  .draggable {
    display: flex;
    padding: 8px;
    background: #fff;
    margin-bottom: 8px;
    border-radius: 8px;
    align-items: center;
    gap: 16px;
    .text {
      flex-grow: 1;
    }
    .pill {
      display: inline-flex;
      font-size: 14px;
      background: #ccc;
      border-radius: 8px;
      color: #fff;
      padding: 4px 8px;
      &.an {
        background: $is-blau;
      }
      &.ag {
        background: $is-rot;
      }
      &.fh {
        background: $lime-accent;
        color: #000;
      }
    }
  }
}

@import "../vars";

.Step{
  position: relative;
  z-index: 5;
  width: 25%;

  @media screen and (max-width: $bp-phone) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .Step-Headline{
    display: flex;
    width: 100%;
    padding: 32px;
    @media screen and (max-width: $bp-phone) {
      align-items: center;
      justify-content: center;
      padding: 16px;
    }
  }
  .Step-Bubble{
    flex-grow: 1;
    background: $red;
    border-radius: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    position: relative;
    text-align: center;
    .arbeitgeber &{
      background: $blue;
    }
    @media screen and (max-width: $bp-phone) {
      aspect-ratio: auto;
      border-radius: 0;
      background: none !important;
      justify-content: flex-start;
    }
    h3{
      color: #fff;
      text-transform: uppercase;
      margin: 0;
      font-weight: 700;
      @media screen and (max-width: $bp-phone) {
        color: #000;
        order: 2;
      }
    }
    h4{
      display: flex;
      background: $lime;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      color: $red;
      font-size: 32px;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      font-family: $font-radial;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      .arbeitgeber &{
        background: $blue-accent;
        color: $blue;
      }
      @media screen and (max-width: $bp-phone) {
        position: relative;
        order: 1;
        margin-right: 16px;
      }
    }
    @media screen and (min-width: $bp-phone) {
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 150px;
        top: 50%;
        left: calc(100% - 30px);
        height: 5px;
        background: url("../assets/steps_bg.svg") center left repeat-x;
        .arbeitgeber &{
          background-image: url("../assets/steps_bg_blue.svg");
        }
      }
    }
  }
  p{
    font-weight: 500;
    margin: 0;
    padding: 0 32px;
    @media screen and (max-width: $bp-phone) {
      padding: 0 16px;
      margin-left: 64px + 16px;
      text-align: left;
      margin-top: -24px;
    }
  }

  &:last-child{
    .Step-Bubble{
      &:after{
        display: none;
      }
    }
  }
}
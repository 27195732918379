@import "../vars";

.Steps{
  width: 100%;
  margin: 100px 0;
  position: relative;
  .Steps-image{
    width: 100%;
    picture, img{
      width: 100%;
    }
  }

  .Steps-text{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    gap: 20px;
    position: relative;
    z-index: 40;
    background: rgba(#fff, .6);
    box-shadow: 0 0 20px 20px rgba(#fff, .6);
    @media screen and (max-width: $bp-phone) {
      flex-direction: column;
    }
  }
  .Steps-Banner{
    display: flex;
    flex-direction: column;
    //background: $red;
    color: #fff;
    text-align: center;
    align-items: center;
    padding: 32px;
    margin-top: 32px;
    position: relative;
    z-index: 40;
    a{
      color: $red;
      text-decoration: none;
      border-radius: 4px;
      padding: 4px;
      display: inline-block;
      .arbeitgeber &{
        color: $blue;
      }
      &:hover{
        background: $red-shade-darker;
        text-decoration: none;
        color: #fff;
        .arbeitgeber &{
          background: $blue-shade-darker;
        }
        .haendler &{
          color: $red;
          background: $lime-accent-shade;
        }
      }
    }
    @media screen and (max-width: $bp-phone) {
      padding: 0 16px;
    }
    p{
      max-width: calc(#{$max-width} / 3);
      font-weight: 500;
    }
    .button{
      padding: 16px 32px;
      background: $lime;
      color: $red;
      text-decoration: none;
      border-radius: 8px;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 16px;
      .arbeitgeber &{
        background: $blue;
        color: $blue-accent;
      }
      @include fp(font-size, 14, 32, 320, $max-width-pure);
      @media screen and (max-width: $bp-phone) {
        width: 100%;
      }
      &:hover{
        background: $lime-accent-shade;
        text-decoration: none;
        color: $red;
        .arbeitgeber &{
          background: $blue-shade-darker;
          color: $blue-accent;
        }
      }
    }
  }
}
@import "../vars";

.TextContent{
  display: grid;
  width: 100%;
  margin: 10vh auto;
  max-width: $max-width;
  grid-gap: 64px;
  grid-template-columns: 4fr 5fr;

  @media screen and (max-width: $bp-phone) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .TextContent-image{
    max-height: 60vh;
    @media screen and (max-width: $bp-phone) {
      padding: 0 16px;
    }

    picture, img{
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    @media screen and (max-width: $bp-phone) {
      width: 100%;
      max-width: 100%;
      position: relative;
      picture, img{
        max-height: 60vh;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  .TextContent-text{
    padding-top: 32px;
    @media screen and (max-width: $bp-phone) {
      padding: 32px 16px 0px;
    }
    h2{
      text-transform: uppercase;
      color: $red;
      @media screen and (max-width: $bp-phone) {
        margin: 0;
      }
    }
    p{
      font-weight: 700;
      font-family: $font-radial;
      @include fp(font-size, 16, 24, 320, $max-width-pure);
    }
    ul{
      font-size: 24px;
      font-weight: 700;
      font-family: $font-radial;
      margin-left: 0;
      padding: 16px;
      list-style: none;
      @include fp(font-size, 18, 24, 320, $max-width-pure);
      li{
        padding: 0;
        margin-bottom: 24px;

        &:first-letter{
          //color: $red;
        }
        &::before {
          content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: $red; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
      }
    }
    em{
      font-family: $font_radial;
      font-weight: 700;
      //color: $red;
      font-style: normal;
    }
    .button{
      padding: 16px 32px;
      background: $lime;
      color: $red;
      text-decoration: none;
      border-radius: 8px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 20px;
      display: inline-flex;
      margin-top: 16px;
      &:hover{
        background: $lime-accent-shade;
      }
    }
  }

}
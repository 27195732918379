@import "../_vars.scss";

.Rechner {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  //margin-top: -100px;
  padding: 100px 100px;
  position: relative;

  @media screen and (max-width: $bp-phone) {
    order: 1;
  }

  min-height: calc(100vh - #{$header-height});
  background: $red top left no-repeat;
  background-size: auto 300px;

  .hidden {
    visibility: hidden;
  }

  &.embed {
    min-height: 100%;
    border-radius: 8px;
    .Rechner-HeroImage,
    .Rechner-Slider {
      display: none !important;
    }
    .Rechner-Content {
      margin-top: 0;
    }
    .Rechner-Form {
      margin-top: 0;
    }
  }

  @media screen and (max-width: $bp-phone) {
    background-size: 100%;
    padding: 16px;
  }

  //@media (min-device-pixel-ratio: 2),
  //(min-resolution: 192dpi) {
  //  background-image:url('./../assets/text_abgefahren_guenstig@2x.png');
  //}

  .index_php & {
    background: #fff;
    min-height: auto;
    padding: 32px;
    align-items: flex-start;
  }

  .Bubbles {
    position: absolute;

    // vergleich changes
    right: -50px;
    bottom: -30px;
    //top: 16px;
    //right: 100px;
    //transform: scale(0.5);

    z-index: 50;

    @media screen and (max-width: $bp-phone) {
      position: sticky;
      right: 16px;
      top: 16px;
      bottom: auto;
    }
    &.desktop-only {
      .index_php & {
        display: none !important;
      }
    }
  }

  .Bubble {
    background: $lime-accent;
    color: $red-shade;
    font-size: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    font-weight: 500;
    text-transform: uppercase;
    font-family: $font-radial;

    small {
      font-size: 26px;
      font-family: $font-din;
      font-weight: 500;
      a {
        font-weight: 700;
      }
    }
    &.Big {
      transform: scale(1);
      animation: pulse 2s infinite;
    }
    &.Small {
      background: #fff;
      width: 88px;
      height: 88px;
      transform: translate(0px, -40px);
      font-size: 32px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      small {
        font-size: 10px;
      }
    }
    @media screen and (max-width: $bp-phone) {
      font-size: 16px;
      width: 96px;
      height: 96px;
      small {
        font-size: 12px;
      }
      &.Small {
        font-size: 20px;
        width: 64px;
        height: 64px;
        small {
          font-size: 10px;
        }
      }
    }
  }

  .ButtonContainer {
    width: 100%;
  }

  @media screen and (max-width: $bp-phone) {
    .BubblesContainer {
      display: flex;
      width: 96px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 64px;
      right: 8px;
      //transform: scale(0.8);
      .Bubble {
        border: 1px solid $red;
      }
    }
  }
}

.Rechner-Content {
  display: flex;
  width: 100%;
  height: auto;
  overflow-y: visible;
  margin: 0 auto;
  gap: 128px;
  align-items: flex-end;
  flex-direction: row;
  //position: relative;
  .index_php & {
    align-items: flex-start;
    margin-top: 0;
    gap: 0;
  }
  @media screen and (max-width: $bp-phone) {
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin-top: 50px;
    width: 100%;
    .main-row {
      display: grid;
      grid-template-columns: 2;
      .radioGroupContainer {
        grid-column: 1 / span 2;
      }
    }
  }
}

.Rechner-Slider {
  width: 40vw;
  // vergleich changes
  position: relative;

  @media screen and (min-width: $bp-phone) {
    // vergleich changes
    height: 60vh;
    //height: 100vh;
    flex-grow: 1;
  }
  @media screen and (max-width: $bp-phone) {
    width: 100%;
    height: auto;
  }

  .index_php & {
    display: none !important;
  }

  .slick-list {
    overflow: visible;
  }
  .slick-dots {
    bottom: 50px;
    @media screen and (max-width: $bp-phone) {
      //top: 0;
      //bottom: auto;
      bottom: 0;
    }
    li {
      button {
        &:before {
          color: #fff;
        }
      }
    }
  }
  .slick-track {
    .slick-slide {
      //padding: 0 200px;
    }
  }
}

.Rechner-BikeText {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  pointer-events: none;
  color: #fff;
}

.Rechner-Image {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  position: relative;
  overflow: visible;
  z-index: 5;
  padding: 0 20px;
  //width: 60vw;
  height: 60vh;

  .index_php & {
    display: none !important;
  }

  @media screen and (max-width: $bp-phone) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 0;
    //max-height: 50vh;
  }

  picture {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
    @media screen and (max-width: $bp-phone) {
      position: relative;
      width: 100%;
      height: auto;
    }
    img {
      width: auto;
      max-width: 100%;
      height: 100%;
      object-fit: contain;
      @media screen and (max-width: $bp-phone) {
        width: 100%;
      }
    }
  }
}

.Rechner-BikeText {
  @media screen and (max-width: $bp-phone) {
    transform: translateY(20px);
  }
}

.Rechner-HeroImage {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 768px) and (max-height: 1000px) {
    height: 130vh;
  }

  //bottom: 0;
  z-index: 10;
  .index_php & {
    display: none !important;
  }

  picture,
  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 40% 0%;
    @media screen and (max-width: $bp-phone) {
      height: 90vh;
    }
  }
}

.Rechner-Awards {
  position: absolute;
  z-index: 15;
  display: flex;
  justify-content: end;
  gap: 8px;
  @media screen and (min-width: $bp-phone) {
    left: 32px;
    bottom: 32px;
    //top: 32px;
  }
  @media screen and (max-width: $bp-phone) {
    top: 64px + 16px;
    right: 16px;
  }
}

.Rechner-Form {
  background: $red-shade;
  color: $lime-accent;
  font-weight: 500;
  padding: 16px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 10;
  flex: 1 0;

  h1 {
    word-break: keep-all;
    hyphens: none;
  }

  @media screen and (max-width: $bp-phone) {
    margin: 20vh 0px 0;
    width: 100%;
  }

  .index_php & {
    margin: 0;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    @media screen and (max-width: $bp-phone) {
      font-size: 28px;
      max-width: calc(100% - 96px);
    }
  }

  small {
    a {
      color: #fff;
    }
  }

  .infoText {
    p {
      @include fp(font-size, 12, 14, 320, 500);
    }
  }

  .subline {
    margin-top: 0;
    color: #fff;
    @include fp(font-size, 14, 18, 320, 500);
    @media screen and (max-width: $bp-phone) {
      max-width: calc(100% - 96px);
    }
  }

  input,
  label {
    font-weight: 500;
    width: 100%;
  }

  label {
    color: #fff;
    display: flex;
    margin-bottom: 8px;
    padding-bottom: 0;
    @include fp(font-size, 10, 16, 320, 500);
  }

  input {
    background: $red-shade-darker;
    color: $lime-accent;
    border: 0;
    padding: 8px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    &::placeholder {
      color: rgba(#fff, 0.4);
    }
    &:focus {
      border: none;
      outline: none;
    }
  }

  .extensionToggle {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 16px;
    cursor: pointer;
    img {
      transition: all 0.2s ease-in-out;
      &.rotated {
        transform: rotate(180deg);
        &:last-child {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .indent {
    padding-left: 2em;
  }

  .extendedOptions {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  @supports (-webkit-text-stroke-width: 1px) {
    span.outline {
      color: $red-shade;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $lime-accent;
      -webkit-text-fill-color: $red-shade;
    }
  }

  .labelContainer {
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    label {
      width: auto;
    }
    button {
      appearance: none;
      border: none;
      background: none;
      display: inline-block;
      width: auto;
      white-space: nowrap;
      @include fp(font-size, 12, 16, 320, 500);
      color: $lime;
      cursor: pointer;
      border-radius: 4px;
      padding: 0 8px;
      &:hover {
        color: $red-shade-darker;
        background: $lime-accent;
      }
    }
  }

  .summary {
    text-align: center;
    .col {
      flex: 1 0;
      span,
      .textTransition {
        justify-content: center;
        align-items: center;
      }
      small {
        .textTransition {
          display: inline-flex !important;
        }
      }
    }
    &.internal {
      flex-direction: column;
      gap: 8px;
      .col {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        //flex-direction: row-reverse;
        grid-template-columns: 80px auto;
        text-align: right;
        grid-gap: 8px;
        width: 100%;
        span,
        .textTransition {
          width: 80px;
          order: 2;
          text-align: right;
          justify-content: flex-end;
        }
        br {
          display: none;
        }
        small {
          font-size: 1em;
          text-align: left;
          flex-grow: 1;
          order: 1;
        }
      }
    }
    @media screen and (max-width: $bp-phone) {
      small {
        font-size: 12px;
      }
    }
  }

  .radioGroupContainer,
  .checkboxGroupContainer {
    display: flex;
    //width: 100%;
  }

  .radioGroupContainer {
    flex-direction: column;
    gap: 8px;
    label {
      margin: 0;
    }
    .radioGroup {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 100%;
      background: $red-shade-darker;
      border-radius: 8px;
      justify-content: space-evenly;
      label {
        display: flex;
        flex: 1 0;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0;
        border-radius: 8px;
        padding: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background: rgba($lime-accent, 0.1);
        }
        &.checked {
          background: $lime-accent;
          color: $red-shade-darker;
          &:hover {
            background: $lime-accent-shade;
          }
        }

        input {
          display: none;
        }
      }
    }
  }

  .checkboxGroupSet {
    display: flex;
    flex-direction: column;
    gap: 4px;
    //margin-top: 16px;
    //margin-bottom: 16px;
  }

  .checkboxGroupContainer {
    &:hover {
      input {
        opacity: 1;
      }
    }
    label {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      input[type="checkbox"] {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        display: grid;
        place-content: center;

        appearance: none;
        background-color: $lime-accent;
        font: inherit;
        color: currentColor;
        //border: 0.15em solid currentColor;
        border-radius: 0.15em;
        //transform: translateY(-0.075em);
        transition: 120ms all ease-in-out;

        opacity: 0.8;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }

        &:checked {
          opacity: 1;
        }

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          //transform: scale(0);
          opacity: 0;
          transition: 120ms all ease-in-out;
          //box-shadow: inset 1em 1em $is-rot;
          background: url("../assets/ic_check.svg") center center no-repeat;
          background-size: contain;
        }

        &:checked::before {
          opacity: 1;
          //transform: scale(1);
        }
      }
      span {
        // additional fields for pendlerpauschale
        display: inline-flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-left: 8px;
        input {
          width: 60px;
        }
      }
    }
  }

  .button {
    display: flex;
    width: 100%;
    background: $lime-accent;
    color: $red-shade;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 16px 8px;
    border-radius: 8px;
    text-transform: uppercase;
    font-family: $font-radial;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    .textTransition {
      margin-left: 4px;
      display: inline-flex !important;
    }
    @media screen and (min-width: $bp-phone) {
      //margin-top: 32px;
    }
    @include fp(font-size, 18, 24, 320, $max-width-pure);
    &:hover {
      background: $lime-accent-shade;
    }
  }

  .calculating {
    opacity: 0.7 !important;
  }

  .textTransition {
    height: 1em !important;
  }
}

.Rechner-Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px;
  animation: fadeIn 0.5s;
  @media screen and (max-width: $bp-phone) {
    padding: 32px 16px;
  }
  .Rechner-Modal-Close {
    position: absolute;
    right: 32px;
    top: 32px + 16px;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 99999;
    @media screen and (max-width: $bp-phone) {
      right: 16px;
    }
  }
  .Rechner-Modal-Content {
    padding: 32px;
    color: $red;
    background: $lime;
    border-radius: 8px;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    animation: moveIn 0.5s;
    max-width: 800px;
    .row {
      gap: 32px;
    }
    @media screen and (max-width: $bp-phone) {
      padding: 24px;
      .row {
        flex-direction: column;
      }
    }
    h1 {
      margin: 0;
    }

    h1 {
      line-height: 100%;
    }
    h2 {
      line-height: 100%;
      margin-top: 0;
      margin-bottom: 1rem;

      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    .elment {
      border: 2px dotted $red;
      padding: 8px;
      margin-bottom: 8px;
    }

    .block-red {
      color: $lime;
      text-align: center;
      background: $red;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    h3 {
      line-height: 100%;
      margin-top: 0;

    }
    h4 {
      line-height: 100%;
      font-size: 18px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 4px;

    }
    h5 {
      word-break: break-word;
      line-height: 130%;
      margin-top: 0;
      margin-bottom: 0;
    }

    p {
      color: #000;
      font-weight: 500;
      line-height: 140%;
      margin-top: 0;
      margin-bottom: 0;
    }

    ul {
      padding-top: 0px;
      padding-left: 16px;
      padding-bottom: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    li {
      color: #000;
      line-height: 120%;

      em {
        font-weight: 500;
        color: $red;
      }
      & > li {
        margin-top: 8px;
      }
    }

  }
  .Rechner-Modal-Closer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($lime, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($lime, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($lime, 0);
  }
}

@import "../vars";

.Footer{
  display: flex;
  position: relative;
  margin-top: 350px;
  background: $red;
  padding: 128px 16px;
  .arbeitgeber &{
    background: $blue;
  }
  .haendler &{
    background: $lime;
  }
  .index_php &{
    display: none !important;
  }
  @media screen and (max-width: $bp-phone) {
    margin-top: 64px;
    padding: 16px;
  }
  h4{
    margin: 0 0 16px;
  }
  p{
    margin: 0;
  }
  a{
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    .haendler &{
      color: #000;
    }
    &:hover{
      background: $red-shade-darker;
      text-decoration: none;
      color: #fff;
      .arbeitgeber &{
        background: $blue-shade-darker;
      }
      .haendler &{
        color: $red;
        background: $lime-accent-shade;
      }
    }
  }
  ul{
    padding: 0;
    margin: 0;
    li{
      list-style: none;
      //margin-bottom: 8px;
    }
  }
  .Footer-Content{
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    color: #fff;
    display: flex;
    .haendler &{
      color: #000;
    }
    @media screen and (max-width: $bp-phone) {
      flex-direction: column;
      padding: 16px 0;
      gap: 32px;
    }
    .left{
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      gap: 32px;
      .logo{
        width: 64px;
        img{
          width: 100%;
          &.red{
            display: none;
          }
          .haendler &{
            &.white{
              display: none;
            }
            &.red{
              display: block;
            }
          }
        }
      }
      .text{
        flex: 1 0;
      }
    }
    .right{
      display: flex;
      .row{
        gap: 64px;
        @media screen and (max-width: $bp-phone) {
          flex-direction: column;
          gap: 32px;
        }
      }
    }
  }
}
@import "../../../vars";

.MultiMap {
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: $max-width;

  .MultiMap-Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
    @media screen and (max-width: $bp-phone) {
      flex-direction: column;
      padding: 0 16px;
    }
    h2 {
      text-transform: uppercase;
      color: $red;
      font: $font-radial;
      font-size: 48px;
      flex-grow: 1;
      margin: 0;
      .arbeitgeber & {
        color: $blue;
      }
      .haendler & {
        color: $red;
      }
      @media screen and (max-width: $bp-phone) {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }
    .radioGroupContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 480px;
      @media screen and (max-width: $bp-phone) {
        width: 100%;
      }
      label {
        margin: 0;
      }
      .radioGroup {
        display: flex;
        flex-direction: row;
        gap: 16px;
        width: 100%;
        background: $red;
        border-radius: 8px;
        justify-content: space-evenly;
        overflow: hidden;
        label {
          display: flex;
          flex: 1 0;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin: 0;
          border-radius: 8px;
          padding: 8px;
          cursor: pointer;
          transition: all 0.3s ease;
          color: #fff;
          @media screen and (max-width: $bp-phone) {
            font-size: 14px;
          }
          &:hover {
            background: rgba($lime-accent, 0.1);
          }
          &.checked {
            background: $lime-accent;
            color: $red-shade-darker;
            &:hover {
              background: $lime-accent-shade;
            }
          }
          &:first-child {
            margin-left: -2px;
          }
          &:last-child {
            margin-right: -2px;
          }

          input {
            display: none;
          }
        }
      }
    }
  }
  .MultiMap-decoration {
    width: 100%;
  }
  .MultiMap-Group {
    flex: 1 0;

    display: flex;
    flex-direction: column;
    gap: 0px;

    @media screen and (min-width: $bp-phone) {
      overflow-x: hidden;
      overflow-y: auto;
    }
    @media screen and (max-width: $bp-phone) {
      padding: 16px;
      transform: none;
    }

    .noSearchResult {
      text-align: center;
      color: #fff;
      margin-top: 24px;
    }
  }
  .MultiMap-Content {
    display: flex;
    flex-direction: row;
    height: 90vh;
    max-height: 800px;

    @media screen and (min-width: $bp-phone) {
      height: 90vh;
    }
    .MultiMap-Search {
      padding: 16px;
      background: $red;
      .col {
        gap: 8px;
      }
      .arbeitgeber & {
        background: $blue-shade-darker;
      }
      .haendler & {
        background: $lime;
      }
      .searchbox {
        background: $red-shade-darker;
        color: #fff;
        .arbeitgeber & {
          background: $blue;
          border-color: $blue-accent;
        }
        .haendler & {
          background: $lime-shade-darker;
          color: #000;
          border-color: $lime;
        }
        &::placeholder {
          //color: rgba(#fff, 1);
          color: rgba(#fff, 0.6);
          font-size: 16px;
          .haendler & {
            color: #000;
          }
        }
      }
      .place {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        //justify-content: center;
      }
      button {
        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
        height: 100%;
        display: flex;
        padding: 8px;
        border-radius: 4px;
        &:hover {
          background: $red-shade-darker;
        }
      }
    }
    .MultiMap-List {
      width: 100%;
      max-width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      background: $red-shade-darker;
      border: 1px solid $red-shade-darker;
      //border-left: none;
      //border-right: none;
      .arbeitgeber & {
        background: $blue;
        border-color: $blue;
      }
      .haendler & {
        background: $lime;
        border-color: $lime;
      }
      @media screen and (max-width: $bp-phone) {
        display: none;
      }
    }
    .MultiMap-Map {
      flex: 1 0;
      @media screen and (max-width: $bp-phone) {
        padding: 0 16px;
      }
    }
  }
  .MultiMap-Banner {
    display: flex;
    flex-direction: column;
    background: $lime;
    color: $red;
    text-align: center;
    align-items: center;
    padding: 32px;
    margin-top: 20px;
    @media screen and (max-width: $bp-phone) {
      padding: 16px;
      margin: 16px;
    }
    .arbeitgeber & {
      background: $blue;
      color: $blue-accent;
    }
    .haendler & {
      background: $lime;
      color: $red;
    }
    p {
      font-weight: 500;
    }
    .button {
      padding: 8px 16px;
      background: $red;
      color: #fff;
      text-decoration: none;
      border-radius: 8px;
      text-transform: uppercase;
      font-weight: 500;
      .arbeitgeber & {
        background: $blue-accent;
        color: $blue;
      }
      .haendler & {
        background: $red;
      }
      &:hover {
        background: $red-shade-darker;
        .arbeitgeber & {
          background: $blue-shade-darker;
          color: $blue-accent;
        }
        .haendler & {
          background: $red-shade-darker;
        }
      }
    }
  }
}

@import "_vars.scss";

.searchbox {
  padding: 8px 16px;
  background: #fff;
  color: #000;
  font-size: 20px;
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  border: 1px solid $red;
  border-radius: 8px;
  position: relative;
  z-index: 100;
  &::placeholder {
    color: $red;
  }
  &:focus {
    border: 1px solid $red-shade-darker;
    box-shadow: none;
    outline: none;
  }
}

.row {
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.suspenseSection {
  position: relative;
  .suspenseFallback {
    width: 100%;
    text-align: center;
  }
  &.videoSection {
    width: 100%;
    .suspenseFallback {
      aspect-ratio: 16/9;
      background: #fafafa;
    }
  }
  &.multiMapSection {
    min-height: 60vh;
    @media screen and (max-width: $bp-phone) {
      margin-bottom: 32px;
    }
  }
}

.react-cookie-law-dialog {
  top: auto !important;
  bottom: 0;
  background-color: $lime !important;
  font-size: 16px !important;
  .react-cookie-law-msg {
    font-size: 16px !important;
  }
  .react-cookie-law-button-wrapper {
    display: flex;
    @media screen and (max-width: $bp-phone) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    button {
      background: $red !important;
      border: none;
      padding: 8px 16px !important;
      border-radius: 8px;
      @media screen and (max-width: $bp-phone) {
        margin-left: 0 !important;
        margin-top: 8px !important;
      }
      &:first-child {
        // manage
        order: 1;
        border: 1px solid $red;
        background: transparent !important;
        span {
          color: $red !important;
        }
        &:hover {
          background: $red !important;
          span {
            color: #fff !important;
          }
        }
      }
      &:last-child {
        // decline all
        order: 2;
      }
      &:first-child,
      &:last-child {
        // manage and decline
      }
      &:nth-child(2) {
        // accept all
        order: 3;
      }
    }
  }
  .react-cookie-law-policy {
    font-size: 16px !important;
    margin-left: 0 !important;
    color: $red !important;
  }
  .react-cookie-law-option-wrapper {
    label {
      color: #000 !important;
    }
  }
}

.mobileMap {
  background: pink;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: url("assets/map.jpg") center center no-repeat;
  background-size: cover;
  .mobileMapButton {
    display: inline-flex;
    flex-grow: 0;
    background: $lime;
    color: $red;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 700;
    border: none;
    @include fp(font-size, 16, 20, 320, $max-width-pure);
    &:hover {
      background: $lime-accent-shade;
    }
  }
}

.embed-responsive-item {
  width: 100%;
  display: flex;
}

.HilfeHaendler {
  h1 {
    @include fp(font-size, 32, 48, 320, $max-width-pure);
  }
  h2 {
    @include fp(font-size, 24, 32, 320, $max-width-pure);
    margin-top: 2em;
  }
}

.Collapsible-Trigger-Text {
  text-transform: uppercase;
}

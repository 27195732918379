@import "../../../vars";

.MultiMapResult{
  display: flex;
  flex-direction: row;
  background: #fff;
  color: #000;
  text-decoration: none;
  align-items: stretch;
  justify-content: center;
  position: relative;
  padding: 16px;
  //gap: 16px;
  border: none;
  border-bottom: 1px solid $red-shade-darker;
  text-align: left;

  cursor: pointer;
  appearance: none;

  .arbeitgeber &{
    border-bottom-color: $blue-shade-darker;
  }
  .haendler &{
    border-bottom-color: $lime;
  }

  &:hover{
    background: mix(#fff, $red, 90);
    .arbeitgeber &{
      background: mix(#fff, $blue, 90);
    }
    .haendler &{
      background: mix(#fff, $lime, 90);
    }
  }


  .MultiMapResult-image{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    width: 40px;
    height: 100%;
    overflow: hidden;
    margin-left: 16px;
    picture, img{
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .MultiMapResult-text{
    position: relative;
    z-index: 10;
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4{
      margin: 0;
      font-size: 18px;
    }
    p{
      margin: 0;
      font-size: 14px;
    }
  }

  .loading-indicator {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      border: 4px solid $red;
      opacity: 1;
      border-radius: 50%;
      animation: loading-indicator 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
  @keyframes loading-indicator {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

}
$font-radial: 'Radial', 'DIN Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu',;
$font-din: 'DIN Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

$is-rot: #E30613;
$is-blau: #164194;

$red: #E0334C;
$red-shade: #F03755;
$red-shade-darker: #B2293F;

$blue: #474395;
$blue-shade: #4646AA;
$blue-shade-darker: #3A3A8C;

$blue-accent: #83CFED;
$blue-accent-shade: #82DCF0;

$lime: #DBE283;
$lime-accent: #DBE283;
$lime-accent-shade: #DCF064;
$lime-shade-darker: #ADB367;

$max-width: 1320px;
$max-width-pure: 1320;
$bp-phone: 800px;
$header-height: 100px;

@mixin fp($property, $min, $max, $start: 320, $end: $max-width, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  $multiplier: calc(($max - $min) / ($end - $start)) * 100;
  $adder: calc(($min * $end - $max * $start) / ($end - $start));
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}